<template>
  <a-spin tip="图片数据获取中......" :spinning="isLoading">
    <div class="main-box">
      <!-- 这里是人工核查部分的导航栏 -->
      <div class="manual-check-nav">
        <!--时间选择-->
        <!--搜索框1 选择年份-->
        <a-select style="width: 120px" placeholder="请选择年份" v-model:value="theYear" @change="getMonthData">
          <a-select-option v-for="(item, index) in yearList" :key="index" :value="item">{{item}}年</a-select-option>
        </a-select>
        <!--搜索框2 选择月份-->
        <a-select style="width: 120px;" placeholder="请选择月份" v-model:value="theMonth" @change="getDayData">
          <a-select-option v-for="(item,index) in monthArr" :key="index" :value="item.month">{{item.label}}</a-select-option>
        </a-select>
        <!--搜索框3 选择具体日期-->
        <a-select style="width: 170px; margin-right: 10px" placeholder="请选择具体的日期" v-model:value="theTime">
          <a-select-option v-for="(item, index) in timeArr" :key="index" :value="item.date">{{item.label}}</a-select-option>
        </a-select>
        <!--区域选择-->
        <a-select v-model:value="regionId" style="width: 120px; margin-right: 10px;" placeholder="请选择区域">
          <a-select-option value="0">全区</a-select-option>
          <a-select-option value="1">区块1</a-select-option>
          <a-select-option value="2">区块2</a-select-option>
          <a-select-option value="3">区块3</a-select-option>
          <a-select-option value="4">区块4</a-select-option>
          <a-select-option value="5">区块5</a-select-option>
          <a-select-option value="6">区块6</a-select-option>
          <a-select-option value="7">区块7</a-select-option>
          <a-select-option value="8">区块8</a-select-option>
        </a-select>
        <!--区域选择-->
        <a-select
            v-model:value="type"
            style="width: 160px; margin-right: 10px;"
            placeholder="请选择图片类型"
        >
          <a-select-option value="3">已核查图片</a-select-option>
          <a-select-option value="4">全部图片</a-select-option>
        </a-select>
        <a-button type="primary" @click="getPicture">查询图片</a-button>
        <span class="text-span" v-show="imageList.length != 0">
          当前图片：第 {{ index + 1 }} / {{ imageList.length }} 张
        </span>
        <span class="text-go-span" v-show="imageList.length != 0"> 跳转：第 </span>
        <a-input
            v-model:value="indexValue"
            v-show="imageList.length != 0"
            style="width: 60px; margin-left: 10px"
            @pressEnter="keyUpGo"
        />
        <span class="text-go-span" v-show="imageList.length != 0"> / {{ imageList.length }}张图片</span>
      </div>

      <!-- 轮播图模块 -->
      <div class="carousel-box">
        <!-- 轮播图本身 -->
        <div class="carousel-pic">
          <!-- 这个是后端传过来的原始的已经标注过的图 -->
          <div id="human_lable"></div>
          <RightCircleOutlined class="carousel-btn right-button" @click="next" v-show="isNextShow"/>
          <LeftCircleOutlined class="carousel-btn left-button" @click="pre" v-show="isPreShow"/>
        </div>
        <!-- 对原始图进行处理的地方，也就是各种按钮 -->
        <div class="check-button">
          <a-button
            class="a-button"
            @click="initpicture"
          >
            刷新图层
          </a-button>
          <a-button
              class="a-button"
              @click="changImgType"
              :disabled="imageList.length == 0"
          >
            切换图片模式
          </a-button>
          <a-button
              type="primary"
              class="a-button"
              @click="manualCorrect"
              :disabled="imageList.length == 0"
          >
            人工纠错
          </a-button>
          <a-button
              type="primary"
              class="a-button"
              @click="openUploadCharm"
              :disabled="imageList.length == 0 || imageList[index].type === 3"
          >
            上传魅力东疆
          </a-button>
          <!--上传至魅力东疆的模态框-->
          <a-modal
              v-model:visible="visible"
              title="上传至魅力东疆"
              @ok="uploadCharm(index)"
          >
            <p>输入文字描述后点击确定即可上传至魅力东疆板块，点击取消返回</p>
            <a-textarea
                v-model:value="text"
                placeholder="请输入与该图片相关的文字描述"
                allow-clear
                size="large"
                :rows="8"
            />
          </a-modal>
        </div>
      </div>

      <!-- 人工纠错的大盒子 -->
      <a-spin tip="图片图层更新中..." :spinning="isFeatureLoading" style="z-index: 100000">
        <div class="manual-check-box" ref="manual_check_box_ref" style="display: none">
          <div id="human_lable2"></div>
          <div class="h-button">
            <a-button class="a-button" type="primary" @click="uploadCheck">提交人工核查</a-button>
            <a-button class="a-button" type="primary" @click="resetLabel">清空核查结果</a-button>
            <a-button class="a-button" @click="changeEditMode(gFetureStyle1, 'red')">漏识别增补</a-button>
            <a-button class="a-button" @click="changeEditMode(gFetureStyle2, 'green')">错识别删除</a-button>
            <a-button class="a-button" type="danger" @click="closeBox">关闭</a-button>
          </div>
        </div>
      </a-spin>
    </div>
  </a-spin>
</template>

<script>
	import {defineComponent} from "vue";
	import {getAnyDate} from "@/tools/datefmt";
  import {message} from "ant-design-vue";
	import {LeftCircleOutlined, RightCircleOutlined} from "@ant-design/icons-vue";
	import AILabel from "ailabel";
	export default defineComponent({
		components: {
      LeftCircleOutlined,
      RightCircleOutlined
		},
		data() {
			return {
        //选择的年份
        theYear: null,
        //选择的月份
        theMonth: null,
        monthArr: [],
        //日期的选择和搜索，文件导出
        theTime: null,
        timeArr: [],

        yearList: [],

				//轮播图的图片位置
				index: 0,

				// 页面上方的原始图层
				gFetureStyle: {}, //固定样式
				gMap: {}, //原始图层
				gImageLayer: {}, //图片图层
				gFeatureLayer: {}, //标注图层

				// 人工核查的图层
        //固定样式1——红色
        gFetureStyle1: new AILabel.Style({
          strokeColor: "#FF0000", //画笔颜色
          lineWeight: 1.5, //线宽
        }),
        //固定样式2——绿色
        gFetureStyle2: new AILabel.Style({
          strokeColor: "#0db402", //画笔颜色
          lineWeight: 1.5, //线宽
        }),
				gMap1: {}, //人工原始图层
				gImageLayer1: {}, //人工图片图层
				gFeatureLayer1: {}, //人工标注图层

        //提交图层是否等待
        isFeatureLoading: false,

				//请求图片的条件
				page: 1, //第几页
				pageSize: 1000000, //一页多少张图片
				regionId: null, //区域id
        type: null, //图片类型

				//原始图片层的各类详细信息
				imageList: [],

				//上传至魅力东疆时
				text: "", //文本描述
				visible: false, //模态框是否显示
				isNextShow: true, //Next按钮是否显示
				isPreShow: false, //Pre按钮是否显示

        isPythonShow: 0,//是否显示python图片
        //请求图片数据的时候打开加载中进度条的条件
        isLoading: false,

        //保存每张图片的原始绿色标注
        borderList: [],

        //跳转第几页
        indexValue: ""
			};
		},
		methods: {
      //根据年份查月份
      getMonthData() {
        this.monthArr = [];
        this.theMonth = null;
        this.timeArr = [];
        this.theTime = null;
        let url = "http://39.100.158.75:8080/photo/findAllPhotoTimeYear";
        this.$axios({
          url,
          headers: {
            token: this.$storage.get('userinfo').data.token
          },
          params: {
            year: this.theYear
          }
        }).then(res => {
          this.monthArr = [];
          for (let el of res.data.data) {
            el.label = el.label + "月";
            this.monthArr.push(el);
          }
        });
      },
      //根据年份和月份查日期
      getDayData() {
        this.timeArr = [];
        this.theTime = null;
        let url = "http://39.100.158.75:8080/photo/findAllPhotoTimeMonth";
        this.$axios({
          url,
          headers: {
            token: this.$storage.get('userinfo').data.token
          },
          params: {
            year: this.theYear,
            month: this.theMonth
          }
        }).then(res => {
          this.timeArr = res.data.data;
        });
      },
			// 查询图片
			getPicture() {
			  this.isLoading = true;
			  this.isNextShow = true;
			  this.isPreShow = false;
				let api = "http://39.100.158.75:8080/photo/findPhotoListByPage";
				this.$axios({
					url: api,
					method: "GET",
					headers: {
						token: this.$storage.get("userinfo").data.token,
					},
					params: {
						page: this.page,
						limit: this.pageSize,
						startTime: this.theTime === null ? getAnyDate(0) : this.theTime,
						endTime: this.theTime === null ? getAnyDate(0) : this.theTime,
						regionId: this.regionId,
            type: this.type
					},
				}).then(response => {
          message.info(response.data.message);
					this.page = 1;
					this.index = 0;
					if (response.data.data == null) {
						this.$refs.manual_check_box_ref.style.display = "none";
						this.imageList = [];
					} else {
						this.imageList = response.data.data.list;
            this.initpicture();
						// 在这里将每个图片的url改为完整图片的url 去除缩略图的后缀
						for (var i = 0; i < response.data.data.list.length; i++) {
							this.imageList[i].photoUrl = this.imageList[i].photoUrl.substr(0, this.imageList[i].photoUrl.indexOf("?"));
						}
					}
          if (this.imageList.length <= 1) {
            this.isPreShow = false;
            this.isNextShow = false;
          }
					this.isLoading = false;
				});
			},
			// 根据图片id请求图片对应的图层
			getPicFeatures() {
				let url = "http://39.100.158.75:8080/photo/findPhotoLabelByPhotoId";
				return new Promise(resolve => {
					this.$axios({
						url: url,
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							token: this.$storage.get("userinfo").data.token,
						},
						params: {
							photoId: this.imageList[this.index].id,
						},
					}).then(res => {
							resolve(res);
          })
				});
			},
			// 页面上方图层的初始化
      showBasicLevel() {
        //常用样式，矩形的边框颜色和宽度
        this.gFetureStyle = new AILabel.Style({
          strokeColor: "#FF0000", //画笔颜色
          lineWeight: 1.5, //线宽
        });
        //容器对象声明
        this.gMap = new AILabel.Map("human_lable", {
          zoom: 1080, //初始缩放级别
          cx: 0, //初始中心点坐标x
          cy: 0, //初始中心点坐标y
          zoomMax: 1080, //缩放的最大级别
          zoomMin: 400, //缩放的最小级别
        });
      },
      showImageLevel(photoUrl) {
        //图片层实例
        this.gImageLayer = new AILabel.Layer.Image(
            "img1", //实例图层的唯一标志id
            photoUrl, //图像的src
            {w: 1080, h: 607.5}, //图像的原始宽高
            {zIndex: 1} //config，这里的zIndex决定显示的层级
        );
        //图片层实例的添加
        this.gMap.addLayer(this.gImageLayer);
      },
      // 初始化图片
			async initpicture() {
				this.showBasicLevel();
        this.showImageLevel(this.imageList[this.index].photoUrl);
        //图层上作画
        //矢量层的实例
        this.gFeatureLayer = new AILabel.Layer.Feature("featureLayer", {
          zIndex: 2,
          transparent: true,
        });
        //矢量层的添加
        this.gMap.addLayer(this.gFeatureLayer);
        this.getPicFeatures().then(res => {
          let listGreen = res.data.data.green_list;
          let listRed = res.data.data.list;
          console.log(listGreen);
          //分别画红框和绿框
          // 1.画红框
          for (const el of listRed) {
            for (const cel of el.list) {
              delete cel.id;
              delete cel.labelId;
            }
          }
          for (let i = 0; i < listRed.length; i++) {
            let stamp = new Date().getTime();
            let fea = new AILabel.Feature.Rect(
                `feature-${stamp}-${i}`,
                listRed[i].list,
                {id: "red"},
                this.gFetureStyle1
            );
            this.gFeatureLayer.addFeature(fea);
          }
          // 2.画绿框
          for (const el of listGreen) {
            for (const cel of el.list) {
              delete cel.id;
              delete cel.labelId;
            }
          }
          for (let i = 0; i < listGreen.length; i++) {
            let stamp = new Date().getTime();
            let fea = new AILabel.Feature.Rect(
                `feature-${stamp}-${i}`,
                listGreen[i].list,
                {id: "green"},
                this.gFetureStyle2
            );
            this.gFeatureLayer.addFeature(fea);
          }
        });
			},
      // 切换图层
      async changImgType() {
        this.showBasicLevel();
        if (this.isPythonShow === 0) {
          this.isPythonShow = 1;
          //请求图片的识别结果
          let result = await this.$axios({
            url: "http://39.100.158.75:8080/photo/findBlackPhotoByPhotoId",
            method: "GET",
            headers: {
              token: this.$storage.get("userinfo").data.token
            },
            params: {
              photoId: this.imageList[this.index].id
            }
          });
          message.success("识别成功！");
          result = result.data.data;
          this.imageList[this.index].pythonImg = result;
          this.showImageLevel(this.imageList[this.index].pythonImg.photoUrl);
        } else {
          this.isPythonShow = 0;
          this.showImageLevel(this.imageList[this.index].photoUrl);
        }
      },
      // 绘制模式的修改
      changeEditMode(style, id) {
			  let that = this;
        // 设置模式，这里设置的模式是绘制多边形
        this.gMap1.setMode("drawPolygon", style);

        this.gMap1.events.on("geometryDrawDone", function (type, points) {
          // 生成元素唯一标志（时间戳）
          let timestamp = new Date().getTime();
          // 元素添加展示
          let fea = new AILabel.Feature.Rect(
              `feature-${timestamp}`, //某个标注的id
              points, //形如[{x,y},{x,y},{x,y}]
              {id: id}, //要素属性数据
              style //要素样式
          );
          that.gFeatureLayer1.addFeature(fea); //将上面的标注添加进入矢量图层
        });

        // feature-reset监听
        this.gMap1.events.on("featureStatusReset", function () {
          that.gMap1.mLayer.removeAllMarkers();
        });

        this.gMap1.events.on("geometryEditing", function (type, feature, points) {
          if (!that.gMap1.mLayer) return;
          const marker = that.gMap1.mLayer.getMarkerById(`marker-${feature.id}`);
          if (!marker) return;
          const bounds = AILabel.Util.getBounds(points);
          const leftTopPoint = bounds[0]; // 边界坐上角坐标
          marker.update({x: leftTopPoint.x, y: leftTopPoint.y});
        });

        this.gMap1.events.on("featureSelected", function (feature) {
          let cFeature = feature;
          // 删除按钮添加
          const featureBounds = cFeature.getBounds();
          const leftTopPoint = featureBounds[0]; // 边界坐上角坐标
          let deleteMarker = new AILabel.Marker(`marker-${cFeature.id}`, {
            src: "delete.PNG",
            x: leftTopPoint.x,
            y: leftTopPoint.y,
            offset: {
              x: 0,
              y: 0,
            },
            featureId: cFeature.id,
          });
          that.gMap1.mLayer.addMarker(deleteMarker);
          deleteMarker.regEvent("click", function () {
            // 执行选中元素删除
            that.gFeatureLayer1.removeFeatureById(this.info.featureId);
            // 对应删除标注层中删除（x）icon
            that.gMap1.mLayer.removeAllMarkers();
          });
        });

        this.gMap1.events.on("geometryEditDone", function (
            type,
            activeFeature,
            points
        ) {
          activeFeature.update({points});
          activeFeature.show();
        });
      },
			// 人工核查图层的初始化
			initManualCheckPic() {
				// 容器对象声明
				this.gMap1 = new AILabel.Map("human_lable2", {
					zoom: 1080, //初始缩放级别
					cx: 0, //初始中心点坐标x
					cy: 0, //初始中心点坐标y
					zoomMax: 1080, //缩放的最大级别
					zoomMin: 400, //缩放的最小级别
				});

				//图片层实例
				this.gImageLayer1 = new AILabel.Layer.Image(
					"img2", //实例图层的唯一标志id
					this.imageList[this.index].photoUrl, //图像的src
					{w: 1080, h: 607.5}, //图像的初始宽高
					{zIndex: 1} //config，这里的zIndex决定显示的层级
				);
				//图片层实例的添加
				this.gMap1.addLayer(this.gImageLayer1);

				//矢量层的实例
				this.gFeatureLayer1 = new AILabel.Layer.Feature("featureLayer2", {
					zIndex: 2,
					transparent: true,
				});
				//矢量层的添加
				this.gMap1.addLayer(this.gFeatureLayer1);

				//图层上作画
				this.getPicFeatures().then(res => {
          let listGreen = res.data.data.green_list;
					let listRed = res.data.data.list;
					//分别画红框和绿框
          // 1.画红框
					for (const el of listRed) {
						for (const cel of el.list) {
							delete cel.id;
							delete cel.labelId;
						}
					}
					for (let i = 0; i < listRed.length; i++) {
						let stamp = new Date().getTime();
						let fea = new AILabel.Feature.Rect(
							`feature-${stamp}-${i}`,
							listRed[i].list,
							{id: "red"},
							this.gFetureStyle1
						);
						this.gFeatureLayer1.addFeature(fea);
					}
          // 2.画绿框
          for (const el of listGreen) {
            for (const cel of el.list) {
              delete cel.id;
              delete cel.labelId;
            }
          }
          for (let i = 0; i < listGreen.length; i++) {
            let stamp = new Date().getTime();
            let fea = new AILabel.Feature.Rect(
                `feature-${stamp}-${i}`,
                listGreen[i].list,
                {id: "green"},
                this.gFetureStyle2
            );
            this.gFeatureLayer1.addFeature(fea);
          }
					//获取所有框，用于后续的提交绿色标注框的判断操作
          this.borderList = [];
          let featuresInfo = this.gFeatureLayer1.getAllFeatures(); // 返回所有要素数据
          for (const el of featuresInfo) {
            let object = new Object();
            if (el.data.id === "green") {
              object.list = el.points;
              this.borderList.push(object);
            }
          }
				});
			},
			// 查看下一张图片
			next() {
				this.index++;
				if (this.index == this.imageList.length - 1) {
					this.isNextShow = false;
				}
				this.initpicture();
				this.isPreShow = true;
			},
			// 查看上一张图片
			pre() {
				this.index--;
				if (this.index == 0) {
					this.isPreShow = false;
				}
				this.initpicture();

				this.isNextShow = true;
			},
			// 提交图层的接口（两个）
      // 红色图层
			updateRedFeature(lists) {
				let url = "http://39.100.158.75:8080/photo/insertPhotoLabels";
				this.$axios({
					url: url,
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						token: this.$storage.get("userinfo").data.token,
					},
					data: JSON.stringify({
						photoId: this.imageList[this.index].id,
						list: lists,
					}),
				}).then(res => {
          console.log(res);
					if (lists.length === 0) {
            this.imageList[this.index].type = 1;
          } else {
            this.imageList[this.index].type = 3;
          }
          return this.imageList[this.index].type;
				});
			},
      // 绿色图层
      updateGreenFeature(lists) {
			  let url = "http://39.100.158.75:8080/photo/insertPhotoGreenLabels";
        this.$axios({
          url: url,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: this.$storage.get("userinfo").data.token,
          },
          data: JSON.stringify({
            photoId: this.imageList[this.index].id,
            list: lists,
          }),
        }).then(res => {
          message.success(res.data.message);
          if (lists.length === 0) {
            this.imageList[this.index].type = 1;
          } else {
            this.imageList[this.index].type = 3;
          }
          return this.imageList[this.index].type;
        });
      },
			// 人工纠错
			manualCorrect() {
				this.$refs.manual_check_box_ref.style.display = "block";
				this.initManualCheckPic();
				this.changeEditMode(this.gFetureStyle1, "red");
				message.success("人工核查默认为漏识别增补模式");
			},
			// 打开上传至魅力东疆的对话框
			openUploadCharm() {
				this.visible = true;
			},
			// 上传至魅力东疆
			uploadCharm(index) {
				let url = "http://39.100.158.75:8080/showPhoto/insertOneShowPhoto";
				this.$axios({
					url: url,
					headers: {
						"Content-Type": "application/json",
						token: this.$storage.get("userinfo").data.token,
					},
					method: "POST",
					data: JSON.stringify({
						operatorId: this.$storage.get("userinfo").data.userId,
						photoId: this.imageList[index].id,
						message: this.text,
						regionId: this.imageList[index].regionId,
						type: 0,
					}),
				}).then((res) => {
					message.info(res.data.message);
					this.visible = false;
					this.text = "";
				});
			},
			// 提交人工核查
			async uploadCheck() {
				let featuresInfo = this.gFeatureLayer1.getAllFeatures(); // 返回所有要素数据

				//向后端传输红色框框的接口（增加图层）
				// 1.首先要规范传送的数据
				let listGreen = [], listRed = [];
				for (const el of featuresInfo) {
					let object = new Object();
					object.list = el.points;
					if (el.data.id === "green") {
					  object.isGreen = 1;
					  listGreen.push(object);
          } else if (el.data.id === "red") {
					  object.isGreen = 0;
					  listRed.push(object);
          }
				}
        // 2.提交图层
        let a = await this.updateRedFeature(listRed);
				let b = await this.updateGreenFeature(listGreen);
        this.imageList[this.index].type = (a === 1 && b === 1) ? 1 : 3;
			},
			// 重新标注
			resetLabel() {
				this.gFeatureLayer1.removeAllFeatures();
			},
			// 关闭人工核查的框
			closeBox() {
				this.$refs.manual_check_box_ref.style.display = "none";
			},
      // 图片输入跳转
      keyUpGo() {
        if ((this.indexValue * 1 - 1) < 0 || (this.indexValue * 1 - 1) >= this.imageList.length) {
          message.error("错误页码");
        } else {
          this.index = this.indexValue * 1 - 1;
          if (this.index === 0) {
            this.isNextShow = true;
            this.isPreShow = false;
          } else if (this.index === this.imageList.length - 1) {
            this.isNextShow = false;
            this.isPreShow = true;
          } else {
            this.isNextShow = true;
            this.isPreShow = true;
          }
          this.initpicture();
        }
      }
		},
		mounted() {
		  for (let i = 2020; i < 2100; i++) {
		    this.yearList.push(i);
      }
			this.getPicture();
		},
  });
</script>

<style lang="scss" scoped>
.main-box {
  width: 90vw;
  height: 89vh;
  .manual-check-nav {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    .text-span {
      display: inline-block;
      margin-left: 10px;
      font-size: 15px;
      font-weight: 510;
      width: 180px;
      height: 31.33px;
      line-height: 31.33px;
      text-align: center;
      border: 1px solid #ccc;
      user-select: none;
    }
    .text-go-span {
      display: inline-block;
      margin-left: 10px;
      font-size: 15px;
      font-weight: 510;
      height: 31.33px;
      line-height: 31.33px;
      text-align: center;
      border: 1px solid #ccc;
      user-select: none;
      padding: 2px;
    }
  }
  .carousel-box {
    width: 95%;
    height: 92%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .carousel-pic {
      border: 1px solid #ccc;
      position: relative;
      margin-top: -20px;
      width: 1080px;
      height: 607.5px;
      #human_lable {
        width: 1080px;
        height: 607.5px;
        position: relative;
        cursor: pointer;
      }
      .carousel-btn {
        font-size: 55px;
        height: 70px;
        width: 70px;
        z-index: 200;
      }
      .right-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
      }
      .left-button {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
    .check-button {
      width: 15%;
      height: 100%;
      .a-button {
        width: 80%;
        height: 40px;
        margin-left: 10px;
        margin-top: 15px;
        padding: 5px;
        font-size: 18px;
      }
    }
  }
  .manual-check-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    #human_lable2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1080px;
      height: 607.5px;
    }
    .h-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-540px, -342.75px);
      .a-button {
        height: 30px;
        margin-right: 10px;
      }
    }
  }
}
</style>
